import axios from 'axios'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { useForm, UseFormMethods } from 'react-hook-form'
import appSotre from 'src/assets/images/app-store-menor.png'
import googlePlay from 'src/assets/images/google-play.png'
import * as URLS from 'src/config/api/Urls'
import useAppsflyerLinkGenerator from 'src/hooks/useAppsflyerLinkGenerator'

import { getParameterByName, sendDataLayerEvent } from 'src/shared/helpers'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

import AcceptTerms from 'src/components/AcceptTerms/OpenAccount'
import SuperApp from 'src/components/DownloadTheApp/index'

import QRCodeBaixeOApp from 'src/assets/images/qr-code-open-account-pf-modal.png'
import errorImg from 'src/assets/images/shared/erro-pig.png'
import logo from '../images/logo-inter-pf-avulsa.png'

import { handleClickLinkButtonAbrirContaInterCartao } from 'src/hooks/useDataLayer/utilsUseDataLayer'
import { Container, Image } from './style'

type OpenAccountFormProps = {
  customOrigin?: string;
  mobileLink?: string;
  className?: string;
  hasLogo?: string;
  width?: number;
};
interface IFormValues {
  nome: string;
  celular: string;
  email: string;
  cpf: string;
  dataNascimento: string;
}

function getOrigin (origin?: string) {
  const pathName = window.location.pathname

  if (origin) {
    return `site-institucional_${pathName}`
  } else {
    return `site-institucional_${pathName}`
  }
}

function OpenAccountInvitationForm ({
  customOrigin,
  mobileLink,
  className,
  hasLogo,
  width,
}: OpenAccountFormProps) {
  const {
    register,
    errors,
    handleSubmit,
    setValue,
  }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const formStatus = {
    idle: 'idle',
    loading: 'loading',
    sent: 'sent',
    error: 'error',
    filled: 'filled',
  }
  const [ generatedLink, QRCodeDiv ] = useAppsflyerLinkGenerator("https://inter-co.onelink.me/Qyu7/xfwei781")
  const [ status, setStatus ] = useState(formStatus.idle)
  const [ accept, setAccept ] = useState(false)
  const WIDTH_MD = 768
  const windowWidth = useWidth(200)

  const [ sendDatalayerEvents ] = useDataLayer()

  const [ utmSource, setUtmSource ] = useState<
    string | string[] | null | undefined
  >(null)
  const [ utmMedium, setUtmMedium ] = useState<
    string | string[] | null | undefined
  >(null)
  const [ utmCampaign, setUtmCampaign ] = useState<
    string | string[] | null | undefined
  >(null)
  const [ utmContent, setUtmContent ] = useState<
    string | string[] | null | undefined
  >(null)
  const [ utmTerm, setUtmTerm ] = useState<
    string | string[] | null | undefined
    >(null)

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
    setUtmTerm(getParameterByName('utm_term', ''))
  }, [])
  const sendForm = async (data: IFormValues) => {
    setStatus(formStatus.loading)

    const formData = {
      ...data,
      cpf: data.cpf.replace(/\D/g, ''),
      celular: data.celular.replace(/\D/g, ''),
      dataNascimento: data.dataNascimento
        .replace(/\D/g, ',')
        .split(',')
        .reverse()
        .join('-'),
      termoAceito: true,
      origin: getOrigin(customOrigin),
      utmCampaign: utmCampaign?.toString(),
      utmMedium: utmMedium?.toString(),
      utmSource: utmSource?.toString(),
      utmContent: utmContent?.toString(),
      utmTerm: utmTerm?.toString(),
    }

    const title = document.getElementById('formTitle')

    try {
        await axios.put(`${URLS.OPEN_ACCOUNT_V2}`, formData)
        sendDataLayerEvent('form_submitted')
        sendDatalayerEvents({
          section: 'dobra_1',
          section_name: `${title ? title.textContent : 'Peça seu cartão sem anuidade'}`,
          element_action: 'submit',
          element_name: 'Continuar',
          redirect_url: 'null',
         })

        if (WIDTH_MD > windowWidth) {
          window.location.href =
            mobileLink ||
            `https://bancointer.onelink.me/eiWM?pid=lp-abertura-form&c=${utmSource ||
              'Direto'}&af_sub1=${utmCampaign ||
              'Direto'}&af_dp=bancointer%3A%2F%2F`
        } else {
          setStatus(formStatus.sent)
        }
    } catch (e) {
      setStatus(formStatus.error)
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setStatus(formStatus.idle)
  }

  const asFilled = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    sendDataLayerEvent({
      event: 'gtm.click',
      'gtm.elementUrl':
        mobileLink ||
        `https://bancointer.onelink.me/eiWM?pid=lp-abertura-form&c=${utmSource ||
          'Direto'}&af_sub1=${utmCampaign ||
          'Direto'}&af_dp=bancointer%3A%2F%2F`,
    })
    setStatus(formStatus.filled)
  }

  return (
    <>
      {status === formStatus.error && (
        <Container className={`d-flex align-items-center ${className}`}>
          <div className='col-12 text-center mx-auto'>
            <div className='row align-items-center justify-content-center'>
              <div className='col-12 my-3'>
                <img src={errorImg} alt='Imagem de erro' />
              </div>
              <div className='col-12 col-md-7 col-lg-11 my-2 my-md-0'>
                <p className='fs-16 lh-20 text-grayscale--400 mb-3'>
                  Ah não! Ocorreu um erro com a sua solicitação...
                </p>
                <p className='fs-16 lh-20 fs-md-18 lh-md-22 text-grayscale--500 mb-0'>
                  Verifique a sua conexão com a internet e tente novamente.{' '}
                  <div className='back'>
                    <button
                      onClick={handleReturn}
                      title='Clique aqui para voltar'
                      className='bg-transparent text-orange--extra fw-600 border-0'
                    >
                      Clique aqui
                    </button>
                    {' '}para voltar.
                  </div>
                </p>
              </div>
            </div>
          </div>
        </Container>
      )}
      {status === formStatus.sent && (
        <Container
          className={`d-flex position-relative align-items-center justify-content-center ${className}`}
        >
          <div className='col-12 col-md-7 col-lg-12 text-center'>
            <div className='row align-items-center justify-content-center'>
              <div className='col-12 mt-2 mt-lg-4'>
                <p className='fs-24 lh-30 fw-600 text-grayscale--500 mb-0'>
                  <span className='d-md-block'>
                    Prontinho! Falta pouco para
                  </span>{' '}
                  você ter sua conta.
                </p>
                <p className='fs-18 lh-22 text-grayscale--400 mt-3'>
                  <span className='d-lg-block'>
                    Abra a câmera do celular e aponte para
                  </span>{' '}
                  o <strong className='text-grayscale--500'>QR Code</strong>{' '}
                  abaixo para baixar o app.
                </p>
                <div className='col-12 mt-3 mx-auto'>
                  {windowWidth >= WIDTH_MD &&
                    QRCodeDiv
                  }
                  <div>
                    <a href='https://apps.apple.com/br/app/inter-co-conta-cart%C3%A3o-e-pix/id839711154' target='_blank' rel='noreferrer'>
                      <img src={appSotre} className='appStore' />
                    </a>
                    <a href='https://play.google.com/store/apps/details?id=br.com.intermedium&pli=1' target='_blank' rel='noreferrer'>
                      <img src={googlePlay} className='appStore' />
                    </a>
                  </div>
                  <SuperApp />
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
      {status === formStatus.filled && (
        <Container
          className={`d-flex position-relative align-items-center justify-content-center ${className}`}
        >
          <div className='row align-items-center justify-content-center text-center'>
            <div className='d-flex justify-content-center w-100'>
              <Image src={hasLogo || logo} width={width} alt='Logo Inter' />
            </div>
            <div className='col-12 mt-4'>
              <p className='fs-24 lh-30 fw-600 text-grayscale--500 mb-0'>
                <span className='d-block'>Baixe o app e vá em</span> “Abrir uma
                conta gratuita”
              </p>
              <p className='fs-18 lh-22 text-grayscale--400 mt-3'>
                <span className='d-lg-block'>
                  Abra a câmera do celular e aponte para
                </span>{' '}
                o <strong className='text-grayscale--500'>QR Code</strong>{' '}
                abaixo para baixar o app.
              </p>
            </div>
            <div className='col-12 mt-3 mx-auto'>
              {QRCodeDiv}
              <div>
                <a href='https://apps.apple.com/br/app/inter-co-conta-cart%C3%A3o-e-pix/id839711154' target='_blank' rel='noreferrer'>
                  <img src={appSotre} className='appStore' />
                </a>
                <a href='https://play.google.com/store/apps/details?id=br.com.intermedium&pli=1' target='_blank' rel='noreferrer'>
                  <img src={googlePlay} className='appStore' />
                </a>
              </div>
              <SuperApp />
            </div>
          </div>
        </Container>
      )}
      <Container
        className={`${
          status === formStatus.idle || status === formStatus.loading
            ? 'd-flex'
            : 'd-none'
        } align-items-center ${className}`}
      >
        <div className='row mx-0'>
          <div className='col-12 text-center pt-md-4'>
            <h3
              className='fs-20 lh-25 fs-md-24 lh-md-30 mb-2 text-black'
              id='formTitle'
            >
              Abra sua conta gratuita
            </h3>
          </div>
          <div className='col-12'>
            <form
              className='form--default'
              name='open_account'
              onSubmit={handleSubmit(sendForm)}
            >
              <div className='row mt-3'>
                <div
                  className={`col-12 pb-3 px-0 px-md-3 ${errors.nome &&
                    'input-error'}`}
                >
                  <label htmlFor='nome' className='fs-14 lh-18 d-block'>
                    Nome
                  </label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) =>
                          Validations.name(value) ||
                          'Por favor, digite seu nome completo',
                      },
                    })}
                    name='nome'
                    id='nome'
                    type='text'
                    maxLength={100}
                    placeholder='Digite seu nome'
                  />
                  {errors.nome && (
                    <p className='fs-12 text-right mb-0'>
                      {errors.nome.message}
                    </p>
                  )}
                </div>
                <div
                  className={`col-12 pb-3 px-0 px-md-3 ${errors.celular &&
                    'input-error'}`}
                >
                  <label htmlFor='celular' className='fs-14 lh-18 d-block'>
                    Telefone
                  </label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Digite um telefone válido',
                      pattern: {
                        value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                        message: 'Telefone inválido',
                      },
                    })}
                    name='celular'
                    id='celular'
                    type='tel'
                    placeholder='Digite seu telefone'
                    maxLength={15}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setValue(
                        'celular',
                        Masks.MaskPHONE(event.currentTarget.value),
                    )
                    }
                  />
                  {errors.celular && (
                    <p className='fs-12 text-right mb-0'>
                      {errors.celular.message}
                    </p>
                  )}
                </div>
                <div
                  className={`col-12 pb-3 px-0 px-md-3 ${errors.email &&
                    'input-error'}`}
                >
                  <label htmlFor='email' className='fs-14 lh-18 d-block'>
                    E-mail
                  </label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    name='email'
                    id='email'
                    type='text'
                    placeholder='Digite seu e-mail'
                  />
                  {errors.email && (
                    <p className='fs-12 text-right mb-0'>
                      {errors.email.message}
                    </p>
                  )}
                </div>
                <div
                  className={`col-12 d-flex flex-column pb-3 px-0 px-md-3 ${errors.cpf &&
                    'input-error'}`}
                >
                  <label htmlFor='cpf' className='fs-14 lh-18'>
                    CPF
                  </label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Digite um CPF válido',
                      validate: {
                        isCpf: (value: string) =>
                          Validations.cpf(value) || 'CPF Inválido',
                      },
                    })}
                    name='cpf'
                    id='cpf'
                    type='tel'
                    placeholder='Digite seu CPF'
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setValue('cpf', Masks.MaskCPF(event.currentTarget.value))
                    }
                  />
                  {errors.cpf && (
                    <p className='fs-12 text-right mb-0'>
                      {errors.cpf.message}
                    </p>
                  )}
                </div>
                <div
                  className={`col-12 d-flex flex-column px-0 px-md-3 pb-md-3 ${errors.dataNascimento &&
                    'input-error'}`}
                >
                  <label htmlFor='dataNascimento' className='fs-14 lh-18'>
                    Data de Nascimento
                  </label>
                  <input
                    ref={register({
                      required: 'Digite uma data válida',
                      validate: {
                        isDate: (value: string) =>
                          Validations.dateValid(value) || 'Data Inválida',
                      },
                    })}
                    name='dataNascimento'
                    id='dataNascimento'
                    type='tel'
                    placeholder='dd/mm/aaaa'
                    maxLength={10}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setValue(
                        'dataNascimento',
                        Masks.MaskDATE(event.currentTarget.value),
                    )
                    }
                  />
                  {errors.dataNascimento && (
                    <p className='fs-12 text-red--base mb-0 text-right'>
                      {errors.dataNascimento.message}
                    </p>
                  )}
                </div>
                <div className='col-12 d-flex flex-column pt-2 pt-md-0 px-0 px-md-3 mt-md-n2 mt-lg-n1 mt-xl-2'>
                  <AcceptTerms
                    accept={accept}
                    setAccept={setAccept}
                    name='conta-digital-pf'
                  />
                </div>
                <div className='col-12 text-center mt-2 pt-lg-2'>
                  <button
                    type='submit'
                    title='Enviar contato'
                    disabled={!accept || status === formStatus.loading}
                    className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white'
                  >
                    {status === formStatus.loading
                      ? 'Enviando...'
                      : 'Continuar'}
                  </button>
                </div>
              </div>
            </form>
            <div className='text-center pt-4'>
              <span className='fs-14 lh-18 text-grayscale--500'>
                {windowWidth <= WIDTH_MD ? (
                  <a
                    onClick={() => {
                      handleClickLinkButtonAbrirContaInterCartao('dobra_1', 'click button', 'Baixar app e abrir conta agora',
                      mobileLink ||
                      `https://bancointer.onelink.me/eiWM?pid=lp-abertura-form&c=${utmSource ||
                        'Direto'}&af_sub1=${utmCampaign ||
                        'Direto'}&af_dp=bancointer%3A%2F%2F`,
                      )
                    }}
                    className='text-orange--extra fw-600 text-uppercase'
                    href={generatedLink}
                  >
                    Baixar app e abrir conta agora
                  </a>
                ) : (
                  <button
                    onClick={(evt: MouseEvent<HTMLButtonElement>) => {
                      asFilled(evt)
                      handleClickLinkButtonAbrirContaInterCartao('dobra_1', 'click button', 'Baixar app e abrir conta agora', 'null')
                    }}
                    className='text-orange--extra fw-600 bg-transparent border-0 text-uppercase'
                  >
                    Baixar app e abrir conta agora
                  </button>
                )}
              </span>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default OpenAccountInvitationForm
